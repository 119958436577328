export const state = () => ({
  data: null
})

export const mutations = {
  setVisitor (state, visitor) {
    state.data = visitor
  }
}

export const actions = {
  async fetchVisitor ({ commit }, { marketId, language }) {
    try {
      const response = await this.$axios.$get('/visitors/me', {
        params: { market_id: marketId, language }
      })
      commit('setVisitor', response)
      return response
    } catch {
      commit('setVisitor', null)
      return null
    }
  }
}
