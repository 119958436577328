export default async function ({ store, app }, inject) {
  const createVisitor = async () => {
    const currentRoute = app.context.route
    const marketId = currentRoute.params.market_id
    const language = currentRoute.params.language || 'ar'
    await store.dispatch('visitor/fetchVisitor', { marketId, language })
  }

  if (!store.state.visitor.data) {
    await createVisitor()
  }

  const visitor = {
    get data () {
      return store.state.visitor.data
    }
  }

  inject('visitor', visitor)
}
