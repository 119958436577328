export default function ({ $axios, req }) {
  $axios.onRequest((config) => {
    // For server-side requests, forward the original client IP headers
    if (process.server && req) {
      const forwardedFor = req.headers['x-forwarded-for']
      const realIp = req.headers['x-real-ip']

      if (forwardedFor) {
        config.headers['X-Forwarded-For'] = forwardedFor
      }

      if (realIp) {
        config.headers['X-Real-IP'] = realIp
      }
    }
    return config
  })
}
